import {
    WriteBatch,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    updateDoc,
    where,
    writeBatch,
} from "firebase/firestore";
import {
    db,
    functions,
    inventoryItemCollection,
    itemCollection,
    ref,
    storage,
    userCollection,
    vendorInventoryCollection,
    workerItemCollection,
} from "..";
import { Item } from "item";
import { getDownloadURL, uploadBytes } from "firebase/storage";
import { Vendor } from "user";
import { InventoryItem, VendorInventory } from "inventory-item";
import { httpsCallable } from "firebase/functions";
import { ItemState } from "../../../utils/itemUtils";
import { customToast } from "../../../components/public_components/Toast/CustomToast";
import _, { orderBy } from "lodash";

const item = {
    // Use this only for admin User
    async addMasterItem(payload: { masterItemPayload: Item; file: File }) {
        const masterItemRef = doc(itemCollection);
        let imageUrlBucket = "";

        const imageUrl = URL.createObjectURL(payload?.file);
        if (imageUrl) {
            let blob = await fetch(imageUrl).then((r) => r.blob());
            const storageRef = ref(
                storage,
                `item/images/${masterItemRef.id}.jpg`
            );
            await uploadBytes(storageRef, blob);
            // Get the download URL
            await getDownloadURL(storageRef)
                .then((url) => {
                    imageUrlBucket = url;
                })
                .catch((error) => {
                    switch (error.code) {
                        case "storage/object-not-found":
                            console.error("No file Found");
                            break;
                        case "storage/unauthorized":
                            console.error("No permission to upload file");
                            break;
                        case "storage/canceled":
                            console.error("File Upload canceled");
                            break;
                        case "storage/unknown":
                            console.error("Bucket not found");
                            break;
                    }
                });
        }

        const masterItemPayload: Item = {
            id: masterItemRef.id,
            item_sku: payload.masterItemPayload.item_sku,
            item_bill_name: payload.masterItemPayload.item_bill_name,
            category: payload.masterItemPayload.category,
            created_at: payload.masterItemPayload.created_at,
            name_search_term: payload.masterItemPayload.name_search_term,
            display_name: payload.masterItemPayload.display_name,
            description: payload.masterItemPayload.description,
            unit_price: payload.masterItemPayload.unit_price,
            unit_size: payload.masterItemPayload.unit_size,
            bulk_price_offset: payload.masterItemPayload.bulk_price_offset,
            bulk_premium_price_offset:
                payload.masterItemPayload.bulk_premium_price_offset,
            item_image_url: imageUrlBucket,
            grade: payload.masterItemPayload.grade,
            status: payload.masterItemPayload.status,
        };

        let result = null;

        setDoc(masterItemRef, masterItemPayload)
            .then((res) => {
                result = res;
                console.log(
                    "Admin log: Send to DB: ",
                    payload,
                    imageUrlBucket,
                    result
                );
                return { isError: false, message: result };
            })
            .catch((err) => {
                return { isError: true, message: err };
            });
    },
    async fetchMasterItems() {
        const result = [];
        const querySnapshot = await getDocs(itemCollection);
        for (const itemData of querySnapshot.docs) {
            result.push(itemData.data());
        }
        const sortedInSkuItems = _.sortBy(result, (item) =>
            parseInt(item.item_sku, 10)
        );
        return sortedInSkuItems;
    },
    async uploadPrice(itemArray: any) {
        console.log(itemArray);
        for (const item of itemArray) {
            const itemRef = doc(itemCollection, item?.item?.id);
            await updateDoc(itemRef, {
                unit_price: item?.itemPrice,
                bulk_price_offset: item?.itemPriceOffset,
                bulk_premium_price_offset: item?.itemPremiumPriceOffset,
                status: item?.itemStockAvailability,
            });
        }
        customToast(`Prices Updated`, "info");
    },
    async updateBulkOffset(payload: any) {
        try {
            const itemRef = doc(itemCollection, payload?.id);
            await updateDoc(itemRef, {
                unit_price: payload?.unit_price,
                bulk_price_offset: payload?.bulk_price_offset,
            });
            customToast(`Price Offset Updated`, "info");
        } catch (error) {
            customToast(`Error`, "error");
        }
    },
    async updatePremiumBulkOffset(payload: any) {
        try {
            const itemRef = doc(itemCollection, payload?.id);
            await updateDoc(itemRef, {
                unit_price: payload?.unit_price,
                bulk_premium_price_offset: payload?.bulk_premium_price_offset,
            });
            customToast(`Price Offset Updated`, "info");
        } catch (error) {
            customToast(`Error`, "error");
        }
    },
    async updateItemSKU(payload: any) {
        try {
            const itemRef = doc(itemCollection, payload.item.id);
            await updateDoc(itemRef, { item_sku: payload.sku });
        } catch (error) {
            console.error(error);
        }
    },
    async adminUpdateMasterInventoryItemNewPrice(payload: any) {
        try {
            const itemRef = doc(itemCollection, payload.docId);
            await updateDoc(itemRef, {
                unit_price: payload?.newUnitPrice,
                bulk_price_offset: payload?.newOffset,
                bulk_premium_price_offset: payload?.newPremiumOffset,
            });
        } catch (error) {
            console.error(error);
        }
    },
    async adminUpdateMasterInventoryItemStockToggle(payload: any) {
        try {
            const itemRef = doc(itemCollection, payload.docId);
            await updateDoc(itemRef, {
                status: payload?.isInStock
                    ? ItemState.InStock
                    : ItemState.OutOfStock,
            });
        } catch (error) {
            console.error(error);
        }
    },
    async adminUpdateMasterInventoryItemGroup(payload: any) {
        try {
            const itemRef = doc(itemCollection, payload.docId);
            await updateDoc(itemRef, {
                current_group: payload?.value,
            });
        } catch (error) {
            console.error(error);
        }
    },
    async adminAddInventory(payload: {
        selectedVendor: Vendor;
        itemArray: Item[];
    }) {
        console.log("Provider Add inventory: ", payload);
        const vendorDocRef = doc(
            db,
            userCollection.path,
            payload.selectedVendor.id
        );

        const inventoryRef = doc(vendorInventoryCollection);
        try {
            const newInventory: VendorInventory = {
                id: inventoryRef.id,
                display_name: "",
                created_at: new Date(),
                registered_location: payload.selectedVendor.registered_location,
                belongs_to: vendorDocRef,
                is_published: false,
                is_deleted: false,
            };
            await setDoc(inventoryRef, newInventory);

            const batch = writeBatch(db);
            for (const item_inventory of payload.itemArray) {
                const q = query(
                    itemCollection,
                    where("id", "==", item_inventory.id)
                );
                const querySnapshot = await getDocs(q);
                const itemRef = querySnapshot.docs[0].ref;
                const inventoryItemDocRef = doc(inventoryItemCollection);
                const inventoryItem: InventoryItem = {
                    id: inventoryItemDocRef.id,
                    created_at: new Date(),
                    master_item: itemRef,
                    is_price_inherited: false,
                    is_price_offset: false,
                    unit_retail_price_offset: 0,
                    unit_retail_price: item_inventory.unit_price.bulk_price,
                    in_stock: true,
                    belongs_to: inventoryRef,
                };

                batch.set(inventoryItemDocRef, inventoryItem);
            }
            console.log("Committing...");
            await batch.commit();
            console.log("Committed");
            return { isError: false, message: "Added Successfully" };
        } catch (error) {
            await deleteDoc(inventoryRef);
            console.error(error);
            return { isError: true, message: error };
        }
    },
    async getItemDataFromReference(itemRef: any) {
        const docRef = await getDoc(itemRef);
        return docRef.data();
    },
    async fetchVendorInventory(payload: any) {
        const userDocRef = doc(userCollection, payload?.user?.id);
        const vendorQuery = query(
            vendorInventoryCollection,
            where("belongs_to", "==", userDocRef)
        );

        const vendorQuerySnapshot = await getDocs(vendorQuery);
        const vendorInventoryQuery = query(
            inventoryItemCollection,
            where("belongs_to", "==", vendorQuerySnapshot.docs[0].ref)
        );

        const querySnapshot = await getDocs(vendorInventoryQuery);
        const inventoryItemArray: any[] = [];
        for (const inventoryItem of querySnapshot.docs) {
            const inventoryData = inventoryItem.data();
            const itemData = inventoryData.master_item;
            const itemDoc = await getDoc(itemData);
            const itemDocData: any = itemDoc.data();
            inventoryItemArray.push({
                id: inventoryData.id,
                is_price_inherited: inventoryData?.is_price_inherited,
                is_price_offset: inventoryData?.is_price_offset,
                unit_retail_price: inventoryData?.unit_retail_price,
                unit_retail_price_offset:
                    inventoryData?.unit_retail_price_offset,
                display_name: itemDocData?.display_name,
                item_image_url: itemDocData?.item_image_url,
                retail_price: itemDocData?.unit_price.retail_price,
                in_stock: inventoryData?.in_stock,
            });
        }

        return inventoryItemArray;
    },
    async setVendorInventory(payload: any) {
        const inventoryItems = payload.items;
        const batchArray: WriteBatch[] = [];
        batchArray.push(writeBatch(db));
        let operationCounter = 0;
        let batchIndex = 0;

        for (const item of inventoryItems) {
            batchArray[batchIndex].update(
                doc(inventoryItemCollection, item.id),
                {
                    in_stock: item.in_stock,
                    is_price_inherited: item.is_price_inherited,
                    is_price_offset: item.is_price_offset,
                    unit_retail_price: item.unit_retail_price,
                    unit_retail_price_offset: item.unit_retail_price_offset,
                }
            );
            operationCounter++;

            if (operationCounter === 400) {
                batchArray.push(writeBatch(db));
                batchIndex++;
                operationCounter = 0;
            }
        }

        batchArray.forEach(async (batch) => await batch.commit());
    },

    // Retail Buyer
    async fetchNearestInventory(payload: {
        user: any;
        location: {
            latitude: number;
            longitude: number;
        };
    }) {
        console.log("Provider: ", payload);
        let result: any = [];
        const fetchNearestInventory = httpsCallable(
            functions,
            "fetchNearestInventory"
        );
        result = await fetchNearestInventory(payload);
        return result;
    },
};

export default item;
