import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0d1821",
            light: "#4f5b62",
            dark: "#000a12",
        },
        secondary: {
            main: "#ff9800",
            light: "#ffc947",
            dark: "#c66900",
        },
        background: {
            default: "#27292d",
            paper: "#1b1e24",
            highlight: "#4cd96445",
        },
        text: {
            primary: "#ffffff",
            secondary: "#b0bec5",
            disabled: "#757575",
            hint: "#9e9e9e",
        },
        divider: "#424242",
        success: {
            main: "#007d34",
            light: "#a5ddac",
            dark: "#045b27",
        },
        warning: {
            main: "#ff9800",
            light: "#ffc947",
            dark: "#c66900",
        },
        error: {
            main: "#ff3d00",
            light: "#ff7539",
            dark: "#c30000",
        },
        info: {
            main: "#ffffff",
            light: "#64b5f6",
            dark: "#1976d2",
        },
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    borderColor: "#ffffff",
                    color: "#ffffff",
                    "&.Mui-focused": {
                        color: "#ffffff",
                    },
                },
            },
        },
    },
});

export default theme;
