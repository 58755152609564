import { useEffect, useState } from "react";
import {
    Alert,
    Box,
    CircularProgress,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import userSlice from "../../store/slices/user";
import { useLocation } from "react-router-dom";
import { WorkerTaskPermissions } from "../../utils/userUtils";
import orderSlice from "../../store/slices/order";
import { format } from "date-fns";

export default function OrderRejectEstimate() {
    const { state } = useLocation();
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(userSlice.selectCurrentUser);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentOrder, setCurrentOrder] = useState<any>();
    const [currentOrderGroups, setCurrentOrderGroups] = useState<any[]>([]);

    useEffect(() => {
        window.sessionStorage.setItem("market", "0");
        window.sessionStorage.setItem("cart", "0");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("profile", "0");
        setIsLoading(true);
    }, []);

    useEffect(() => {
        fetchRejectData(state?.orderId);
    }, [state]);
    useEffect(() => {
        if (currentOrder) {
            setCurrentOrderGroups(currentOrder);
        }
    }, [currentOrder]);

    const fetchRejectData = async (orderId: string) => {
        const result: any = await dispatch(
            orderSlice.fetchRejectData({
                orderId: orderId,
                deliveryDate: format(state.deliveryDate, "yyyy/MM/dd"),
            })
        );

        setCurrentOrder(result?.payload?.result);
        setIsLoading(false);
    };

    return (
        <Box
            sx={{
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
            pb={10}
            pt={2}
        >
            <Typography
                textAlign={"center"}
                variant="h5"
                color={"text.primary"}
            >
                Order Reject Estimate
            </Typography>

            {isLoading ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        m: 3,
                    }}
                >
                    <CircularProgress color="secondary" />
                </Box>
            ) : (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            m: 2,
                        }}
                    >
                        <TextField
                            fullWidth
                            value={
                                state?.orderId +
                                    format(state.deliveryDate, " yyyy/MM/dd") ||
                                0
                            }
                            label="Order Id"
                            variant="outlined"
                            color="info"
                        />
                    </Box>

                    {currentOrder !== undefined ? (
                        <>
                            <Grid
                                container
                                sx={{
                                    justifyContent: "center",
                                    p: 2,
                                }}
                                spacing={2}
                            >
                                <Grid item xs={12}>
                                    {!currentUser?.permission_array?.includes(
                                        WorkerTaskPermissions.ORDER_GENERATE_INVOICE
                                    ) && (
                                        <Alert
                                            variant="standard"
                                            severity="warning"
                                        >
                                            No Permission.
                                        </Alert>
                                    )}
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={12}>
                                        <Typography
                                            textAlign={"left"}
                                            variant="body1"
                                            color={"secondary"}
                                        >
                                            * Reject Compared to Purchase Order
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            textAlign={"left"}
                                            variant="body1"
                                            color={"secondary"}
                                        >
                                            FLCode
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            textAlign={"left"}
                                            variant="body1"
                                            color={"secondary"}
                                        >
                                            Name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            textAlign={"right"}
                                            variant="body1"
                                            color={"secondary"}
                                        >
                                            Reject
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {currentOrder.map(
                                    (item: any, index: number) => {
                                        return (
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                key={index}
                                            >
                                                <Grid item xs={4}>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="body1"
                                                        color={"secondary"}
                                                    >
                                                        {item.item_sku}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography
                                                        textAlign={"left"}
                                                        variant="body1"
                                                        color={"secondary"}
                                                    >
                                                        {item.item_name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography
                                                        textAlign={"right"}
                                                        variant="body1"
                                                        color={"secondary"}
                                                    >
                                                        {(
                                                            item.start_quantity -
                                                            item.end_quantity
                                                        ).toFixed(2)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                )}
                            </Grid>
                        </>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </Box>
    );
}
