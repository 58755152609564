import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    List,
    ListItem,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import CircularProgress, {
    CircularProgressProps,
} from "@mui/material/CircularProgress";
import { useAppDispatch, useAppSelector } from "../../hooks";
import orderSlice from "../../store/slices/order";
import { Link, useNavigate } from "react-router-dom";
import itemSlice from "../../store/slices/item";
import ongoing_logo from "../../assets/images/ongoing.png";
import completed_logo from "../../assets/images/completed.png";
import ready_logo from "../../assets/images/ready.png";
import BuildIcon from "@mui/icons-material/Build";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DownloadIcon from "@mui/icons-material/Download";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import { CSVLink } from "react-csv";
import userSlice from "../../store/slices/user";
import CustomDatePicker from "../public_components/datePicker/CustomDatePicker";
import { format } from "date-fns";
import { Recycling, ShoppingBasket, Visibility } from "@mui/icons-material";
import { WorkerTaskPermissions } from "../../utils/userUtils";

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number }
) {
    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

const OrderItem = ({ item, isLoading, handleOpen }: any) => {
    const calculateProgress = (row: any) => {
        const totalNumberOfItems = row?.items.length;
        let completedItems = 0;
        for (const item of row?.items) {
            if (item.is_ready) {
                completedItems += 1;
            }
        }
        const progress = (completedItems / totalNumberOfItems) * 100;
        return progress;
    };

    if (isLoading) {
        return <CircularProgress color="secondary" />;
    }

    return (
        <Card
            sx={{
                backgroundColor: "background.default",
                boxShadow: 4,
                borderRadius: 0,
            }}
            onClick={() => {
                handleOpen(item);
            }}
        >
            <Grid
                container
                sx={{
                    m: 2,
                }}
            >
                <Grid item xs={6}>
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 700,
                        }}
                    >
                        {item.sort_order_id}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                        <strong>{item.order_status}</strong>
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={6}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgressWithLabel
                        color="secondary"
                        value={calculateProgress(item)}
                    />
                </Grid>
            </Grid>
        </Card>
    );
};

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    isOrderLoading: boolean;
    setIsLoading: any;
    tab: string;
}

function SimpleDialog(props: SimpleDialogProps) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(userSlice.selectCurrentUser);
    const selectedOrder: any = useAppSelector(
        orderSlice?.selectWorkerSelectedOrderObject
    );
    const [currentOrder, setCurrentOrder] = useState<any>([]);
    const [orderId, setOrderIds] = useState<any>(null);
    const [orderItems, setOrderItems] = useState<any>([]);
    const [invoiceLinkLoading, setInvoiceLinkLoading] =
        useState<boolean>(false);

    const { onClose, open, isOrderLoading, setIsLoading, tab } = props;

    useEffect(() => {
        const fetchItem = async () => {
            setIsLoading(true);
            if (orderItems) {
                const cartItemArray: any[] = [];
                for (const cartItem of orderItems) {
                    const finalCartItem = await getItemFromRef(cartItem);
                    cartItemArray.push(finalCartItem);
                }
                setCurrentOrder(cartItemArray);
            }
            setIsLoading(false);
        };
        fetchItem();
    }, [orderItems]);

    useEffect(() => {
        if (!selectedOrder) {
            return;
        }
        setOrderItems(selectedOrder?.items);
        try {
            setOrderIds({
                id: selectedOrder?.id,
                sortId: selectedOrder?.sort_order_id,
                name: selectedOrder?.order_name,
                delivery_date: format(
                    selectedOrder?.delivery_date?.toDate()?.toDateString(),
                    "dd/MM/yyyy"
                ),
                is_invoice_generated: selectedOrder?.is_invoice_updated,
                order_invoice_download_url:
                    selectedOrder?.order_invoice_download_url,
            });
        } catch (error) {
            console.error(error);
        }
    }, [selectedOrder]);
    useEffect(() => {
        setIsLoading(isOrderLoading);
    }, [isOrderLoading]);

    const handleClose = () => {
        dispatch(orderSlice.clearWorkerSelectedOrderObject());
        onClose();
    };
    const getItemFromRef = async (item: any) => {
        try {
            const itemData: any = await dispatch(
                itemSlice.getItemDataFromReference(item.item)
            );
            return {
                id: itemData.payload?.id,
                name: itemData.payload?.display_name?.si,
                quantity: item?.quantity,
                image: itemData.payload?.item_image_url,
                unit: itemData.payload?.unit_size,
                isReady: item?.is_ready,
            };
        } catch (error) {
            console.error(error);
            return {
                id: "Error",
                name: "Error",
                quantity: item?.quantity,
                image: "#",
                unit: "kg*",
                isReady: item?.is_ready,
            };
        }
    };

    const handleCheckMark = async (cartItemId: string, checkboxValue: any) => {
        await dispatch(
            orderSlice.updateOrderItemSourcedWorker({
                order: selectedOrder,
                cartItemId,
                checkboxValue,
            })
        );
    };
    const handleAllReady = async () => {
        await dispatch(
            orderSlice.updateOrderAllItemsSourceCheckbox({
                order: selectedOrder,
            })
        );
    };
    const handleModify = async () => {
        navigate(`/worker/orders/modify/${selectedOrder.id}`, {
            state: selectedOrder.id,
        });
    };
    const handletagCrates = async () => {
        navigate(`/worker/orders/crate/${selectedOrder.id}`, {
            state: selectedOrder.id,
        });
    };
    const handleViewCrates = async () => {
        navigate(`/worker/orders/crate-view/${selectedOrder.id}`, {
            state: {
                orderId: selectedOrder?.id,
                orderName: selectedOrder?.order_name,
                deliveryDate: selectedOrder?.delivery_date_text,
            },
        });
    };
    const handleReject = async () => {
        navigate(`/worker/orders/reject-estimate/${selectedOrder.id}`, {
            state: {
                orderId: selectedOrder?.id,
                orderName: selectedOrder?.order_name,
                deliveryDate: selectedOrder?.delivery_date_text,
            },
        });
    };
    const handleGetInvoice = async () => {
        setInvoiceLinkLoading(true);
        const result: any = await dispatch(
            orderSlice.fetchOrderInvoice(selectedOrder?.id)
        );
        setInvoiceLinkLoading(false);
    };
    const handleGroupedInvoice = async () => {
        setInvoiceLinkLoading(true);
        navigate(`/worker/orders/grouped-order/${selectedOrder.id}`, {
            state: {
                orderId: selectedOrder?.id,
            },
        });

        setInvoiceLinkLoading(false);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle align="center" sx={{ fontSize: 26 }}>
                <strong>{orderId?.sortId}</strong>
            </DialogTitle>
            <Typography align="left" variant="caption" sx={{ ml: 2 }}>
                Order ID: {orderId?.id}
            </Typography>
            <Typography align="left" variant="caption" sx={{ ml: 2 }}>
                Name: {orderId?.name}
            </Typography>
            <Typography align="left" variant="caption" sx={{ ml: 2 }}>
                Delivery Date: {orderId?.delivery_date}
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={orderId?.is_invoice_generated ? 12 : 6}>
                    {currentUser?.permission_array?.includes(
                        WorkerTaskPermissions.ORDER_GENERATE_INVOICE
                    ) && (
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={handleGetInvoice}
                        >
                            {!invoiceLinkLoading ? (
                                <>
                                    Invoice <CloudSyncIcon />
                                </>
                            ) : (
                                <CircularProgress size={30} />
                            )}
                        </Button>
                    )}
                </Grid>
                {currentUser?.permission_array?.includes(
                    WorkerTaskPermissions.ORDER_GENERATE_INVOICE
                ) && (
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={handleGroupedInvoice}
                        >
                            Group Invoice <ReceiptIcon />
                        </Button>
                    </Grid>
                )}

                {orderId?.is_invoice_generated ? (
                    <Grid item xs={6}>
                        <Link
                            to={orderId?.order_invoice_download_url}
                            target="_blank"
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                            >
                                Invoice <DownloadIcon />
                            </Button>
                        </Link>
                    </Grid>
                ) : (
                    <></>
                )}
                <Grid item xs={6}>
                    {currentUser?.permission_array?.includes(
                        WorkerTaskPermissions.ORDER_ITEM_READY
                    ) && (
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={handleAllReady}
                        >
                            All Ready <DoneAllIcon />
                        </Button>
                    )}
                </Grid>
                <Grid item xs={6}>
                    {currentUser?.permission_array?.includes(
                        WorkerTaskPermissions.ORDER_QUANTITY_CHANGE
                    ) && (
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={handleModify}
                        >
                            Modify <BuildIcon />
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {currentUser?.permission_array?.includes(
                        WorkerTaskPermissions.ORDER_QUANTITY_CHANGE
                    ) && (
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={handleReject}
                        >
                            View Reject Estimate <Recycling />
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {currentUser?.permission_array?.includes(
                        WorkerTaskPermissions.ORDER_TAG_CRATE
                    ) && (
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={handletagCrates}
                        >
                            Tag Crates <ShoppingBasket />
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={handleViewCrates}
                    >
                        View Crates <Visibility />
                    </Button>
                </Grid>
            </Grid>
            {isOrderLoading ? (
                <List sx={{ pt: 0, minHeight: 100, width: "80vw" }}>
                    <ListItem
                        alignItems="center"
                        disableGutters
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress color="secondary" />
                    </ListItem>
                </List>
            ) : (
                <Box sx={{ pt: 2 }}>
                    {currentOrder.map((item: any, index: number) => (
                        <Card
                            sx={{
                                display: "flex",
                                backgroundColor: "background.default",
                                minHeight: 100,
                                maxHeight: 100,
                                borderRadius: 0,
                                pb: 1,
                            }}
                            key={index}
                        >
                            <Grid
                                container
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <Grid item xs={1}>
                                    {currentUser?.permission_array?.includes(
                                        WorkerTaskPermissions.ORDER_ITEM_READY
                                    ) && (
                                        <Checkbox
                                            color="secondary"
                                            checked={item.isReady}
                                            disabled={tab === "shipped"}
                                            disableRipple
                                            onClick={(event: any) => {
                                                handleCheckMark(
                                                    item.id,
                                                    event.target.checked
                                                );
                                            }}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                        color={"secondary"}
                                    >
                                        <CardContent sx={{ pb: 3 }}>
                                            <Typography variant="h6">
                                                {item?.name}
                                            </Typography>
                                            <Typography variant="subtitle1">
                                                {item?.quantity +
                                                    " " +
                                                    item?.unit}
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Card>
                    ))}
                </Box>
            )}
        </Dialog>
    );
}

const WorkerOrderPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const orders = useAppSelector(orderSlice.selectOrders);
    const [items, setItems] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isOrderLoading, setIsOrderLoading] = useState<boolean>(true);
    const [open, setOpen] = useState<boolean>(false);
    const [tab, setTab] = useState<string>("ongoing");
    const [value, setValue] = useState<string>("ongoing");
    const [confOpen, setConfOpen] = useState<boolean>(false);
    const [deliveryDate, setDeliveryDate] = useState<Date | null>(new Date());

    const handleConfClose = () => {
        setConfOpen(false);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        setTab(newValue);
        setIsLoading(true);
    };

    useEffect(() => {
        window.sessionStorage.setItem("market", "1");
        window.sessionStorage.setItem("cart", "0");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("profile", "0");
    }, []);

    useEffect(() => {
        fetchOrders();
    }, [deliveryDate]);
    useEffect(() => {
        fetchOrders();
    }, [tab]);
    useEffect(() => {
        if (!orders) {
            return;
        }
        setItems(orders);
        setIsLoading(false);
    }, [orders]);

    const fetchOrders = async () => {
        setIsLoading(true);
        await dispatch(
            orderSlice.subscribeWorkerToOrders({ deliveryDate, tab })
        );
    };

    const handleOpen = (data: any) => {
        setIsOrderLoading(true);
        dispatch(orderSlice.syncWorkerSelectedOrder(data.id));
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const handleReject = async () => {
        navigate(`/worker/orders/reject-estimate`, {
            state: {
                orderId: "All",
                orderName: "All Order",
                deliveryDate: deliveryDate,
            },
        });
    };

    return (
        <Box
            sx={{
                pt: 3,
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
        >
            <div>
                <Box
                    sx={{
                        backgroundColor: "background.paper",
                        minHeight: "90vh",
                        height: "100%",
                    }}
                >
                    <Dialog open={confOpen} onClose={handleConfClose}>
                        <DialogTitle>{"Initiate Loading?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                This action will initiate the loading of the
                                orders. Do you want to proceed?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="success"
                                onClick={() => navigate("loading")}
                            >
                                YES
                            </Button>
                            <Button color="error" onClick={handleConfClose}>
                                NO
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <SimpleDialog
                        open={open}
                        onClose={handleClose}
                        isOrderLoading={isOrderLoading}
                        setIsLoading={setIsOrderLoading}
                        tab={tab}
                    />
                    <Container>
                        <CustomDatePicker
                            label={"Delivery Date"}
                            value={deliveryDate}
                            minDate={null}
                            onChange={(newValue: Date | null) =>
                                setDeliveryDate(newValue)
                            }
                        />
                        <Button
                            color="secondary"
                            variant="contained"
                            fullWidth
                            onClick={handleReject}
                        >
                            Reject <Recycling />
                        </Button>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                textColor="secondary"
                                indicatorColor="secondary"
                                scrollButtons="auto"
                            >
                                <Tab
                                    icon={
                                        <img
                                            src={ongoing_logo}
                                            alt="logo"
                                            className="responsive-img"
                                        />
                                    }
                                    label="Ongoing"
                                    value="ongoing"
                                />
                                <Tab
                                    icon={
                                        <img
                                            src={ready_logo}
                                            alt="logo"
                                            className="responsive-img"
                                        />
                                    }
                                    label="Ready"
                                    value="ready"
                                />
                                <Tab
                                    icon={
                                        <img
                                            src={completed_logo}
                                            alt="logo"
                                            className="responsive-img"
                                        />
                                    }
                                    label="shipped"
                                    value="shipped"
                                />
                            </Tabs>
                        </Box>
                    </Container>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                            justifyContent: "center",
                            m: 3,
                            pb: 10,
                        }}
                    >
                        {tab === "ready" && (
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => setConfOpen(true)}
                            >
                                Initiate Loading
                            </Button>
                        )}
                        {isLoading ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    m: 3,
                                }}
                            >
                                <CircularProgress color="secondary" />
                            </Box>
                        ) : items?.length === 0 && !isLoading ? (
                            <Typography
                                variant="h6"
                                color={"secondary"}
                                textAlign={"center"}
                            >
                                No Orders!
                            </Typography>
                        ) : (
                            items?.map((row: any, index: any) => {
                                return (
                                    <OrderItem
                                        key={index}
                                        item={row}
                                        isLoading={isLoading}
                                        handleOpen={handleOpen}
                                    />
                                );
                            })
                        )}
                    </Box>
                </Box>
            </div>
        </Box>
    );
};

export default WorkerOrderPage;
